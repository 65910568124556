<template>
  <b-form-group :label="title" :invalid-feedback="failed" :state="state">
    <b-form-input
      :disabled="readOnly"
      :id="fieldId"
      type="email"
      :placeholder="placeholder"
      :state="state"
      v-model="currentValue"
    ></b-form-input>
  </b-form-group>
</template>

<script>
export default {
  name: 'FieldEmail',
  model: {
    prop: 'textValue',
    event: 'input',
  },
  props: {
    fieldId: String,
    placeholder: String,
    title: String,
    textValue: String,
    state: Boolean,
    errorMessage: String,
    readOnly: Boolean,
  },
  computed: {
    failed() {
      return this.errorMessage || 'Il campo è obbligatorio';
    },
    currentValue: {
      get() {
        return this.textValue;
      },
      set(value) {
        // console.log('updateSelf', value);
        this.$emit('input', value);
      },
    },
  },
};
</script>
